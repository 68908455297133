var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Bônus")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.status === 'B' ? _vm.headers2 : _vm.headers,"search":_vm.search,"items":_vm.listaItens,"show-select":_vm.status === 'N',"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('div',{staticClass:"col-6 pb-0 pl-0 d-flex",staticStyle:{"position":"relative","left":"-10px"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":35,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 pt-1 ml-4",attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('BrToolBar',{attrs:{"configFilter":{ listaSelect: _vm.keys, getItens: _vm.getItens, jsonData: false },"labelTextField":'Busca pelo código da entidade'},on:{"search":_vm.buscar}})],1),_c('v-row',{staticClass:"row no-print"},[_c('div',{staticClass:"col-6 d-flex"},[_c('v-btn',{class:['mr-0',_vm.status === 'N' ? 'br-btn': 'br-btn-activate'],attrs:{"tile":""},on:{"click":function($event){return _vm.buscarBonus('N')}}},[_vm._v(" Pendentes ")]),_c('v-btn',{class:[_vm.status === 'B' ? 'br-btn': 'br-btn-activate'],attrs:{"tile":""},on:{"click":function($event){return _vm.buscarBonus('B')}}},[_vm._v(" Baixados ")]),_c('v-btn',{staticClass:"ml-3 br-btn-activate",attrs:{"tile":"","color":_vm.variables.$colorSecondary,"dark":"","outlined":""},on:{"click":function($event){return _vm.impressao()}}},[_vm._v(" Imprimir ")]),_c('v-btn',{class:['ml-4 br-btn-activate-active'],attrs:{"tile":"","color":_vm.variables.$colorSecondary,"dark":"","outlined":""},on:{"click":function($event){return _vm.baixarTodos()}}},[_vm._v(" Realizar baixa ")])],1)])]},proxy:true},{key:"item.entities.jsonData.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entities.cod)+" - "+_vm._s(item.entities.jsonData.name)+" ")]}},{key:"item.pending.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pending.created)+" ")]}},{key:"item.pending.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pending.updated)+" ")]}},{key:"item.pending.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.status === 'N' ? item.pending.value - item.entries.value : item.pending.value))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.permiteAcao(_vm.$route, 'edit') && _vm.status === 'N')?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){return _vm.baixarTodos(item)}}},[_vm._v(" Baixar ")]):_c('v-chip',{staticStyle:{"color":"white"},attrs:{"color":_vm.variables.colorSecondary}},[_vm._v(" Baixado ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(1)+" - "+_vm._s(1)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.selects),callback:function ($$v) {_vm.selects=$$v},expression:"selects"}})],1)],1),_c('modalMaisInformacoes',{attrs:{"showModal":_vm.showModalInformacoes}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }