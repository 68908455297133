import { mapGetters, mapActions } from 'vuex'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'

import { errorSnackbar, successSnackbar } from '@/core/service/utils'

export default  {
  data: () => ({
    page: 1,
    loadingTable: true,
    loadingPag: false,
    disabled: false,
    search: '',
    activeModal: false,
    selectFilter: '',
    objCampoBusca: [],
    filter: ''
  }),

  mounted () {
    Events.$on('toolbar::selectFilter', (val) => { this.selectFilter = val })
    Events.$on('paginacao::clear', () => { this.page = 1 })
    Events.$on('loadingTable::activate', () => { this.loadingPag = true })
    Events.$on('loadingTable::deactivate', () => { this.loadingPag = false })
  },

  computed: {
    ...mapGetters('globalStore', ['isStatus']),
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('filter', ['objFilter']),
    ...mapGetters('entities', ['listEntityTypes']),
    variables: () => variables,
    numberOfPages () {
      return Math.ceil(this.totalItens / this.$store.getters.ItensPaginacao)
    },

    itemsPerPage () {
      return this.$store.getters.ItensPaginacao
    },

    pageStop () {
      const result = this.$store.getters.ItensPaginacao * this.page
      return result < this.totalItens ? result : this.totalItens
    },

    pageStart () {
      return this.pageStop - this.listaItens.length + 1
    }
  },

  watch: {
    page (e) {
      this.paginacao(e)
    }
  },

  created () {
    this.init()
  },

  methods: {
    // mapeando as actions de tratamento de filtro
    ...mapActions('filter', ['clearObjFilter']),
    ...mapActions('globalStore', ['switchStatus', 'clearSwitchStatus']),
    // mapeando as actions pra salvar na store o numero da pagina das tabelas
    ...mapActions('pagination', ['setPage', 'clearPage'] // limpa os dados da paginação
    ),
    // buscar (payload) {
    //   this.search = payload
    // },
    msgAll(msg, error = false) {
      if (error) {
        return errorSnackbar(msg)
      } 
      return successSnackbar(msg)
    },
    init() {
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    paginacao (pag) {
      this.loadingPag = true
      this.disabled = true
      this.setPage(pag)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      const objFilter = this.objFilter // objeto definido no modal de filtro
      this.getItens({ page: pag, pageSize: this.$store.getters.ItensPaginacao, ...objFilter, ...this.objCampoBusca, _addlFilter: this.filter })
      .finally(() => {
        this.loadingPag = false
        this.disabled = false
      })
    },
    

    buscar(val) {
      this.loadingPag = true
      this.textSearch = val
      if (this.typeBusca && this.typeBusca === 'entities') {
        this.objCampoBusca['_filter'] = { "Entities:cod_ilike":`%${val}%` }
        this
          .getItens({ _filter: { "Entities:cod_ilike":`%${val}%` } })
          .then(result => {
            if (result.data.totalItens < 1) {
              errorSnackbar('Não há dados disponíveis')
            }
          })
          .catch(err => {
            errorSnackbar(err.error)
          })
          .finally(() => { this.loadingPag = false })

          return
      }

      this.objCampoBusca['_filter'] = { name_ilike: `%${val}%` }
      this
        .getItens({ _filter: { name_ilike: `%${val}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },

    idade (data) {
      return Math.floor(moment(new Date()).diff(moment(data, 'YYYY-MM-DD'),'years',true))
    },

    cadastrar () {
      Events.$emit('cadastro::openModalCadastro')
    },

    editItem (item) {
      this.getItem(item).then(() => {
        Events.$emit('cadastro::openModalCadastro', item)
      }).catch(() => {
        errorSnackbar('Error ao selecionar o item')
      })
      // this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja excluir?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.loadingPag = true
          this.deletarItem({id: item.id}).then(() =>{
            successSnackbar('Item excluído com sucesso!')
            this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
              .then(result => {
                if (result.data.totalItens < 1) {
                  errorSnackbar('Não há dados disponíveis')
                }
              })
              .catch(err => {
                errorSnackbar(err.error)
              })
              .finally(() => this.loadingPag = false)
          }).catch(err => {
            this.loadingPag = false
            errorSnackbar(err.error)
          })
        }
      })
    },

    getColor (date, inverso = false) {
      return (inverso ? !this.getStatus(date) : this.getStatus(date))  ? this.variables.colorSecondary : this.variables.colorError
    },

    getStatus (date) {
      return moment(date, 'YYYY-MM-DD').isSame(moment('1970-01-01'), 'year') ? !!1 : !!0
    },

    textStatus (date) {
      return this.getStatus(date) ? 'Ativo' : 'Inativo'
    },

    getEntityType (id) {
      let str = this.listEntityTypes.filter(item => item.id === id)[0].text
      str = str.toLowerCase()
      str = str.charAt(0).toUpperCase() + str.substr(1)

      return str
    }
  },
  beforeDestroy () {
    this.clearSwitchStatus()
    this.clearItens() // limpa os dados da tabela
    this.clearObjFilter() // limpa os dados do modal de filtro
    this.clearPage() // limpa os dados da paginação
    this.objCampoBusca = [] // limpa os dados do campo de busca
    Events.$off('toolbar::selectFilter')
    Events.$off('toolbar::selectFilter')
    Events.$off('paginacao::clear')
    Events.$off('loadingTable::activate')
    Events.$off('loadingTable::deactivate')
  }
}