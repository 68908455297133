<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Bônus</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers=" status === 'B' ? headers2 : headers"
            :search="search"
            :items="listaItens"
            :show-select="status === 'N'"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            v-model="selects"
            :items-per-page.sync="itemsPerPage"
          > 
            <template v-slot:top>
              <v-row>
                <div class="col-6 pb-0 pl-0 d-flex" style="position:relative; left: -10px">
                  <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Data"
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        class="mt-0 pt-1 ml-4"
                        v-bind="attrs"
                        readonly/>
                    </template>
                    <v-date-picker v-model="selectedDate"/>
                  </v-menu>
                </div>
                <BrToolBar
                  @search="buscar"
                  :configFilter="{ listaSelect: keys, getItens: getItens, jsonData: false }"
                  :labelTextField="'Busca pelo código da entidade'"
                 />
                
              </v-row>
              <v-row class="row no-print">
                <div class="col-6 d-flex">
                  <v-btn :class="['mr-0',status === 'N' ? 'br-btn': 'br-btn-activate'] " tile  @click="buscarBonus('N')" > Pendentes </v-btn>
                  <v-btn :class="[status === 'B' ? 'br-btn': 'br-btn-activate']" tile   @click="buscarBonus('B')" > Baixados </v-btn>
                  <v-btn class="ml-3 br-btn-activate" tile :color="variables.$colorSecondary" dark outlined  @click="impressao()" > Imprimir </v-btn>
                  <v-btn :class="['ml-4 br-btn-activate-active'] " tile :color="variables.$colorSecondary" dark outlined  @click="baixarTodos()" > Realizar baixa </v-btn>
                  
                </div>
                
              
                
              </v-row>
            </template>

            <template v-slot:item.entities.jsonData.name="{ item }">
              {{  item.entities.cod }} - {{ item.entities.jsonData.name }}
            </template>

            <template v-slot:item.pending.created="{ item }">
              {{  item.pending.created }}
            </template>

            <template v-slot:item.pending.updated="{ item }">
              {{  item.pending.updated }}
            </template>

            <template v-slot:item.pending.value="{ item }">
              {{ status === 'N' ?  item.pending.value - item.entries.value : item.pending.value  | currency }}
              <!-- {{ item.pending.value | currency }} -->
            </template>
            
            <!-- <template v-slot:item.status="{ item }">
                <v-chip v-if="tipo === 'p'" :color="variables.$colorSecondary" dark> Pendente </v-chip>
                <v-chip v-else :color="variables.$colorPrimary" dark> Baixado </v-chip>
            </template> -->

            <template v-slot:item.action="{ item }">
              <v-btn                
                v-if="permiteAcao($route, 'edit') && status === 'N'"
                small
                @click="baixarTodos(item)"
                tile
                outlined
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Baixar
              </v-btn>
             <v-chip v-else :color="variables.colorSecondary" style="color: white"> Baixado </v-chip>

            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ 1 }}
                  -
                  {{ 1 }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
<!-- 
            <template slot="body.append">
                <tr style="background: #4CAF50; color:white;">
                    <th class="text-center" colspan="2" style="font-weight: 400 !important">Nº Bilhetes: {{ totalGeral.bilhetes ? totalGeral.bilhetes : '0,00' | currency }} </th>
                    <th class="text-center"  colspan="2" style="font-weight: 400 !important">Valor jogos: {{ totalGeral.jogos? totalGeral.jogos : '0,00' | currency }} </th>
                    <th class="text-center" colspan="2" style="font-weight: 400 !important">Prêmios Pendentes: {{ totalGeral.pendentes? totalGeral.pendentes : '0,00' | currency }} </th>
                </tr>
              </template> -->
         </v-data-table>
        </v-skeleton-loader>      
      </div>

      <!-- <v-btn class="mx-2 fab-cadastrar-usuario no-print" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn> -->

      <modalMaisInformacoes :showModal="showModalInformacoes" />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'
import Events from '@/core/service/events'
import  { map, size } from 'lodash'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import moment from 'moment'
const namespaceStore = 'financeiroBonus'

export default {
  name: 'CadastroBonificacoes',
  components: {
    BrToolBar: () => import('./components/toolbar'),
    modalMaisInformacoes: () => import('./components/modalMaisInformacoes')
  },
  mixins: [listagemTables],
  data: () => ({
    selectedDate: new Date().toISOString().substr(0, 10),
    dateFormat: new Date().toISOString().substr(0, 10),
    tipo: 'N',
    selects: [],
    headers: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      // { align: 'start', class: 'table-header', text: 'Código Rev.', value: 'entities.cod' },
      { align: 'start', class: 'table-header', text: 'Revenda', value: 'entities.jsonData.name' },
      { align: 'start', class: 'table-header', text: 'ID Jogo', value: 'lottery.id' },
      { align: 'start', class: 'table-header', text: 'Nº Jogo', value: 'lottery.gameNumber' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'pending.created' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'pending.value' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false }
    ],
    headers2: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Revenda', value: 'entities.jsonData.name' },
      { align: 'start', class: 'table-header', text: 'ID Jogo', value: 'lottery.id' },
      { align: 'start', class: 'table-header', text: 'Nº Jogo', value: 'lottery.gameNumber' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'pending.created' },
      { align: 'start', class: 'table-header', text: 'Data da baixa', value: 'pending.updated' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'pending.value' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false }
    ],
    showModalInformacoes: false,
    keys: {
      'e.jsonData.name': '',
      'lg:gameNumber': '',
    }
  }),
  watch: {
    selectedDate (val) {
      this.setStatus({ tipo: this.status, date: val })
      this.dateFormat = this.formatDate(val)
      this.buscarBonus(this.status)
    }
  },
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'status']),
    computedDateFormatted () {
      let data = this.formatDate(this.selectedDate)
      return data
    },
    selecionou () {
      return this.selects.length
    },
    totalGeral () {
      return {}
    },
    itemsPerPage () {
      return this.totalItens
    },
    numberOfPages () {
      return 1
    },
  },
  mounted () {
    this.buscarBonus()
    this.setStatus({ tipo:'N', date: this.selectedDate })
  },
  methods: {
     formatDate (date) {
       if (!date) return null
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    impressao() {
      window.print()
    },
    init() {},
    ...mapActions(namespaceStore, ['getItens', 'deletarItem', 'getItem', 'bonificationDownload', 'setStatus']),
    openModalInformacoes (id) {
      Events.$emit('modalInformacoes::bonificacoes', id)
    },
    buscarBonus(tipo = 'N') {
      this.setStatus({ tipo: tipo, date: this.selectedDate })
      this.loadingTable = true
      this.getItens({ _addlFilter: this.filter ? this.filter : undefined })
        .finally(() => this.loadingTable = false)
    },

    buscar(val) {
      this.loadingTable = true
      this.getItens({ _addlFilter: {"e:cod_ilike": `${val}%`} })
        .finally(() => this.loadingTable = false)
    },

    baixarTodos (item = null) {
      const vm = this
      item !== null && this.selects.push(item)

      if (!size(this.selects)) {
        errorSnackbar('Selecione um Bônus')
        return false
      }
      const ids = map(this.selects, v => v.pending.id)
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja baixar ?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.loadingPag = true
          this.bonificationDownload(ids).then(() => {
            this.selects = []
            this.loadingPag = false
            successSnackbar('Itens baixados com sucesso!')
            this.buscarBonus(vm.status)
          }).catch(err => {
            this.loadingPag = false
            errorSnackbar(err.error)
          })
        } else {
          vm.selects = []
        }
      })
    },
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';

  .cadastro-usuario .v-data-table__wrapper table {
    min-width: 900px;
  }

  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
